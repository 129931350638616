.news {
    max-width: 1170px;
    margin: 0 auto 70px;

    @media screen and (max-width: 39.9375em) {
        margin-bottom: 30px;
    }

    @media screen and (min-width: 40em) and (max-width: 1024px) {
        padding: 0 10px;
    }
}

.news__top {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 39.9375em) {
        justify-content: center;
    }
}

.news__link {
    display: block;
    font-family: 'SourceSansPro-Regular';
    font-size: 14px;
    color: $text-color;
    text-decoration: underline $active-button-color;
    transition: .3s;
    margin-bottom: 30px;
    padding: 5px;

    @media screen and (max-width: 39.9375em) {
        display: none;
    }

    &:hover {
        background-color: $active-button-color;
        color: $main-background-color;
        text-decoration: none;
    }
}

.news__link_for_mobile {
    display: none;

    @media screen and (max-width: 39.9375em) {
        display: block;
        margin: 0 auto;
        width: 80px;
        text-align: center;
    }
}

.news__wrapper {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 2fr 1fr 1fr;
    grid-template-rows: repeat(2, 170px);

    @media screen and (max-width: 39.9375em) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    @media screen and (min-width: 40em) and (max-width: 1024px) {
        grid-gap: 10px;
    }
}

.news__article {
    background : {
        image: url('/images/article.jpg');
        size: cover;
        position: center;
        repeat: no-repeat;
    }
    position: relative;
    transition: .3s;
    text-decoration: none;

    @media screen and (max-width: 39.9375em) {
        width: 300px;
        height: 170px;
        margin-bottom: 20px;
    }

    &:first-child {
        grid-row-start: 1;
        grid-row-end: span 2;

        .news__preview {
            padding: 35px 37px;

            .news__text {
                font-size: 16px;
            }
        }
    }

    &:hover {
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);

        .news__text {
            color: #eaeaea;
        }
    }
}

.news__preview {
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 24px 17px;
}

.news__date {
    font-family: 'BebasNeue-Bold';
    font-size: 14px;
    color: $main-background-color;
    letter-spacing: .05em;
    padding: 5px 10px;
    background-color: $active-button-color;
    margin-bottom: 15px;
    display: inline-block;
}

.news__text {
    font-family: 'SourceSansPro-Regular';
    font-size: 14px;
    color: $main-background-color;
    max-height: 3.6em;
    line-height: 1.2em;
    overflow-y: hidden;
    text-shadow: 1px 1px 1px $text-color;
}
