.services {
    max-width: 1170px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 1024px) {
        justify-content: center;
    }
}

.service {
    max-width: 570px;
    width: 100%;
    height: 190px;
    flex-shrink: 0;
    margin-bottom: 70px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    text-decoration: none;
    transition: .3s;

    @media screen and (max-width: 39.9375em) {
        max-width: 300px;
        height: 150px;
        margin-bottom: 30px;
    }

    &:hover {
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    }
}

.service__picture {
    background : {
        repeat: no-repeat;
    }
    width: 270px;
    height: 190px;
    margin-right: 35px;
    flex-shrink: 0;

    @media screen and (max-width: 39.9375em) {
        width: 130px;
        height: 150px;
        margin-right: 0;
    }
}

.service__picture_type_tickets {
    background-image: url('/images/tickets-service.png');
    background-size: cover;
    background-position: left top;

    @media screen and (max-width: 39.9375em) {
        background-position: right top;
    }
}

.service__picture_type_info {
    background-image: url('/images/usdlogo.png');
    background-size: 43%;
    background-position: center;
}

.service__h {
    margin: 0;
    font-family: 'BebasNeue-Bold';
    font-size: 24px;
    color: $text-color;
    letter-spacing: .05em;

    @media screen and (max-width: 39.9375em) {
        font-size: 22px;
    }
}

.service__refinement {
    font-family: 'SourceSansPro-Regular';
    font-size: 16px;
    color: $logo-text-color;
    margin-top: 17px;

    @media screen and (max-width: 39.9375em) {
        font-size: 14px;
        margin-top: 14px;
    }
}
