.body {
    // max-width: 1440px;
    margin: 0 auto;
    background-color: $main-background-color;
}

.head__toolbar-wrapper {
    background-color: $background-color;
}

.head__toolbar {
    max-width: 1160px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 1024px) {
        box-sizing: border-box;
        padding: 5px 10px;
    }
}

.head__nav-label {
    display: none;

    @media screen and (max-width: 1024px) {
        display: block;
        background: {
            image: url('/images/burger.svg');
            size: contain;
            position: center;
            repeat: no-repeat;
        }
        cursor: pointer;
        width: 18px;
        height: 12px;
    }
}

.head__accessibility-wrapper {
    display: flex;

    @media screen and (max-width: 39.9em) {
        margin-left: auto;
    }

    @media screen and (min-width: 20em) and (max-width: 1024px) {
        margin: 0;
    }
}

.head__accessibility-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $active-button-color;
    transform: skewX(315deg);
    width: 270px;
    height: 50px;
    border: none;
    cursor: pointer;

    @media screen and (max-width: 1024px) {
        background-color: transparent;
        transform: none;
        background : {
            image: url('/images/accessibility-eye.svg');
            size: contain;
            position: center;
            repeat: no-repeat;
        }
        width: 20px;
        height: 20px;
    }

    &:hover {

        .head__accessibility-btn-insides {
            color: #eaeaea;
        }
    }
}

.head__accessibility-btn-insides {
    transform: skewX(-315deg);
    font-family: 'SourceSansPro-Regular';
    font-size: 14px;
    color: $main-background-color;
    padding-left: 25px;
    background : {
        image: url('/images/accessibility-eye.svg');
        size: 16px 9px;
        position: left center;
        repeat: no-repeat;
    }

    @media screen and (max-width: 1024px) {
        display: none;
    }
}

.head__accessibility-btn-shadow {
    width: 59px;
    height: 50px;
    transform: skewX(315deg);
    background-color: $active-button-color;

    @media screen and (max-width: 1024px) {
        display: none;
    }

    &:nth-child(2) {
        opacity: 0.4;
    }

    &:nth-child(3) {
        opacity: 0.3;
    }

    &:nth-child(4) {
        opacity: 0.2;
    }

    &:nth-child(5) {
        opacity: 0.1;
    }
}

.head__toolbar-right {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.head__toolbar-phonenumber-h {
    font-family: 'SourceSansPro-Regular';
    font-size: 13px;
    color: $main-background-color;
    margin-right: 20px;
    
    @media screen and (max-width: 39.9375em) {
        display: none;
    }
}

.head__toolbar-phonenumber {
    font-family: 'SourceSansPro-Bold';
    font-size: 13px;
    color: $main-background-color;
    margin-right: 12px;
    text-decoration: none;

    @media screen and (max-width: 39.9375em) {
        display: none;
    }
}

.head__toolbar-e-mail {
    font-family: 'SourceSansPro-Bold';
    font-size: 13px;
    color: $main-background-color;
    padding: 10px 30px;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    text-decoration: none;

    @media screen and (max-width: 39.9375em) {
        display: none;
    }
}

.head__toolbar-social-networks {
    display: flex;
    align-items: center;
    padding: 10px 30px;
    border-left: 1px solid rgba(255, 255, 255, 0.2);

    @media screen and (max-width: 39.9375em) {
        border: none;
    }
}

.head__toolbar-facebook {
    display: block;
    width: 14px;
    height: 14px;
    background : {
        image: url('/images/facebook.svg');
        size: contain;
        position: center;
        repeat: no-repeat;
    }
    margin-right: 12px;

    &:hover {
        background-image: url('/images/facebook-hover.svg');
    }
}

.head__toolbar-vk {
    display: block;
    width: 14px;
    height: 14px;
    background : {
        image: url('/images/vk.svg');
        size: contain;
        position: center;
        repeat: no-repeat;
    }

    &:hover {
        background-image: url('/images/vk-hover.svg');
    }
}

.head__toolbar-search-form {
    padding: 10px 30px;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    position: relative;

    @media screen and (max-width: 39.9375em) {
        padding: 10px;
    }
}

.head__search-input {
    position: absolute;
    top: 0;
    right: 100%;
    width: 0;
    overflow: hidden;
    transition: .5s;
    font-family: 'SourceSansPro-Regular';
    font-size: 12px;
    color: $text-color;
    box-sizing: border-box;
    height: 34px;
    background-color: transparent;
    border: none;
}

.head__search-input_on {
    background-color: $main-background-color;
    width: 233px;
    padding: 0 18px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
}

.head__search-button {
    display: block;
    width: 12px;
    height: 14px;
    background : {
        image: url('/images/search.svg');
        size: contain;
        position: center;
        repeat: no-repeat;
    }
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.head__container {
    max-width: 1160px;
    margin: 0 auto;
    min-height: 194px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 1024px) {
        box-sizing: border-box;
        min-height: initial;
        padding: 30px 10px;
    }
}

.head__logo-wrapper {
    display: flex;
    align-items: center;
    text-decoration: none;
}

.head__logo {
    background : {
        image: url('/images/usdlogo.png');
        size: contain;
        position: left center;
        repeat: no-repeat;
    }
    width: 100px;
    height: 133px;
    margin-right: 20px;
    flex-shrink: 0;

    @media screen and (max-width: 39.9375em) {
        width: 41px;
        height: 54px;
        margin-right: 15px;
    }
}

.head__logo-text {
    max-width: 237px;
    flex-shrink: 0;

    @media screen and (max-width: 39.9375em) {
        max-width: 170px;
    }
}

.head__logo-h {
    font-family: 'BebasNeue-Bold';
    font-size: 18px;
    color: $logo-h-color;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    margin-bottom: 11px;

    @media screen and (max-width: 39.9375em) {
        font-size: 14px;
        margin-bottom: 3px;
    }
}

.head__logo-refinement {
    font-family: 'SourceSansPro-Regular';
    font-size: 14px;
    color: $logo-text-color;

    @media screen and (max-width: 39.9375em) {
        font-size: 12px;
    }
}

.head__contact {
    display: flex;
    align-items: center;

    @media screen and (max-width: 39.9375em) {
        display: none;
    }

    @media screen and (min-width: 40em) and (max-width: 1024px) {
        order: 3;
        margin-top: 20px;
    }

    @media screen and (min-width: 40em) and (max-width: 1024px) and (orientation: landscape) {
        width: 50%;
    }
}

.head__contact-image {
    background : {
        size: contain;
        position: center;
        repeat: no-repeat;
    }
    width: 55px;
    height: 29px;
    margin-right: 15px;
    flex-shrink: 0;
}

.head__contact-image_type_ice-palace {
    background-image: url('/images/ice-palace.png');
}

.head__contact-image_type_waterpool {
    background-image: url('/images/waterpool.png');
}

.head__contact-content {
    max-width: 198px;
    width: 100%;
    flex-shrink: 0;
}

.head__contact-h {
    font-family: 'BebasNeue-Bold';
    font-size: 14px;
    color: $text-color;
    letter-spacing: 0.05em;
    margin-bottom: 13px;
}

.head__contact-address {
    font-family: 'SourceSansPro-Regular';
    font-size: 13px;
    color: $text-color;
    margin-bottom: 14px;
}

.head__contact-phonenumber {
    font-family: 'SourceSansPro-Regular';
    font-size: 13px;
    color: $text-color;
    padding-top: 7px;
    border-top: 1px solid rgba(62, 181, 241, 0.15);
    text-decoration: none;
}

@media screen and (max-width: 39.9375em) {
    .head__services {
        display: flex;
        align-items: center;
    }
}

.head__buy-tickets {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $logo-h-color;
    width: 170px;
    height: 50px;
    margin-bottom: 10px;
    box-sizing: border-box;
    font-family: 'SourceSansPro-Regular';
    font-size: 14px;
    color: $main-background-color;
    text-decoration: none;
    padding-left: 21px;
    background : {
        image: url('/images/tickets.svg');
        size: 15px 16px;
        position: 30px center;
        repeat: no-repeat;
    }
    transition: .5s;

    @media screen and (max-width: 39.9375em) {
        width: 30px;
        height: 30px;
        margin-bottom: 0;
        margin-right: 10px;
        font-size: 0;
        padding-left: 0;
        background-position: center;
    }

    &:hover {
        box-shadow: 0 4px 7px rgba(62, 181, 241, 0.7);
    }
}

.head__account-wrapper {
    width: 170px;
    height: 50px;
    box-sizing: border-box;
    border: 1px solid transparent;
    background-color: transparent;
    transition: .5s;
    padding: 0;
    cursor: pointer;

    @media screen and (max-width: 39.9375em) {
        width: 30px;
        height: 30px;
    }

    &:hover {
        box-shadow: 0 4px 7px rgba(62, 181, 241, 0.7);
        border-color: $logo-h-color;
    }
}

.head__account {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $main-background-color;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    font-family: 'SourceSansPro-Regular';
    font-size: 14px;
    color: $logo-h-color;
    border: 1px solid $logo-h-color;
    padding-left: 21px;
    background : {
        image: url('/images/account.svg');
        size: 12px 16px;
        position: 20px center;
        repeat: no-repeat;
    }

    @media screen and (max-width: 39.9375em) {
        font-size: 0;
        padding-left: 0;
        background-position: center;
    }
}

.head__nav-wrapper {
    background-color: $logo-h-color;
}

.head__nav-trigger {
    display: none;
    
    @media screen and (max-width: 1024px) {
        
        &:checked ~ .head__nav > .head__nav-ul {
            display: block;
        }
    }
}

.head__nav {
    max-width: 1160px;
    margin: 0 auto;

    @media screen and (max-width: 1024px) {
        position: absolute;
        left: 0;
        top: 44px;
        width: 100%;
        z-index: 99;
    }
}

.head__nav-ul_level_0 {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 1024px) {
        display: none;
        background-color: $background-color;
        box-sizing: border-box;
        padding: 0 10px;
        overflow: hidden;
        transition: .5s;
        border-top: 1px solid rgba(255, 255, 255, 0.4);
    }
}

.head__nav-li_level_0 {
    flex-grow: 1;
    position: relative;

    @media screen and (max-width: 1024px) {
        flex-grow: 0;
        border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    }

    @media screen and (min-width: 1025px) {

        &:hover > .head__nav-ul_level_1 {
            transform: scale(1, 1);
            overflow: visible;

//             @media screen and (max-width: 39.9375em) {
//                 height: 0;
//                 padding: 0;
//                 overflow: hidden;
//             }
        }
    }
}

.head__nav-link_level_0 {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80px;
    font-family: 'BebasNeue-Bold';
    font-size: 18px;
    color: $main-background-color;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    text-decoration: none;
    transform: skewX(330deg);
    box-sizing: border-box;

    @media screen and (max-width: 1024px) {
        display: block;
        min-height: initial;
        font-size: 14px;
        transform: none;
        padding: 25px 0;
        margin-right: 25px;
    }

    &:hover {
        background-color: $active-button-color;
    }
}

.head__nav-span_level_0 {
    transform: skewX(-330deg);

    @media screen and (max-width: 39.9375em) {
        transform: none;
    }
}

.head__nav-ul_level_1 {
    overflow: hidden;
    position: absolute;
    left: -26px;
    top: 100%;
    z-index: 99;
    background-color: $nav-second-ul-color;
    box-sizing: border-box;
    min-width: 270px;
    border-radius: 10px;
    list-style: none;
    margin: 0;
    padding: 20px 0;
    transition: .5s;
    transform: scale(1, 0);
    transform-origin: top;

    @media screen and (max-width: 1024px) {
        position: static;
        transform: none;
        padding: 0;
        
        &:before {
            content: '';
            position: absolute;
            right: 0;
            top: 25px;
            background : {
                image: url('/images/submenu-down.svg');
                size: contain;
                position: center;
                repeat: no-repeat;
            }
            width: 10px;
            height: 10px;
            transition: .5s;
        }
    }
}

@media screen and (max-width: 1024px) {
    
    .head__nav-li_clicked {
        
        > ul:before {
            // background-image: url('/images/submenu-up.svg');
            transform: rotate(180deg);
        }

        > ul > li {
            height: auto;
        }
    }
}

.head__nav-li_level_1, .head__nav-li_level_2 {
    position: relative;

    @media screen and (max-width: 1024px) {
        height: 0;
    }

    @media screen and (min-width: 1025px) {

        &:hover > .head__nav-ul_level_2 {
            width: 270px;
            transform: scale(1, 1);
        }
    }
}

.head__nav-link_level_1, .head__nav-link_level_2 {
    display: block;
    font-family: 'SourceSansPro-Regular';
    font-size: 16px;
    color: #f2f2f2;
    line-height: 23px;
    text-decoration: none;
    padding: 10px 25px;
    transition: .3s;

    @media screen and (max-width: 1024px) {
        margin-right: 20px;
    }

    &:hover {
        background-color: #2da1d0;
    }
}

.head__nav-ul_level_2 {
    @extend .head__nav-ul_level_1;

    @media screen and (min-width: 1025px) {
        left: 100%;
        top: 0;
        min-width: 0;
        width: 270px;
        height: auto;
        z-index: 100;
        overflow: hidden;
        transform: scale(0, 1);
        transform-origin: left;
        padding: 20px 0;
    }
}
