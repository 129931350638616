.path {
    max-width: 1160px;
    margin: 30px auto 50px;

    @media screen and (max-width: 39.9375em) {
        margin: 20px auto 30px;
        padding: 0 10px;
    }

    @media screen and (min-width: 40em) and (max-width: 1024px) {
        padding: 0 10px;
    }
}

.path__ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
}

.path__li {
    display: flex;
    align-items: center;
    margin-right: 5px;

    &::after {
        content: '';
        background : {
            image: url('/images/path-separator.svg');
            size: contain;
            position: center;
            repeat: no-repeat;
        }
        width: 3px;
        height: 3px;
        display: block;
        margin-left: 5px;
    }

    &:last-child::after {
        display: none;
    }

    &:last-child .path__link {
        color: #9d9d9d;
    }
}

.path__link {
    font-family: 'SourceSansPro-Regular';
    font-size: 12px;
    color: $text-color;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}
