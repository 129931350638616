@import 'src/scss/_variables.scss';
@import 'src/scss/components/_header.scss';
@import 'src/scss/components/_main.scss';
@import 'src/scss/main/_billboard.scss';
@import 'src/scss/main/_subdivisions.scss';
@import 'src/scss/main/_timetables.scss';
@import 'src/scss/main/_services.scss';
@import 'src/scss/main/_news.scss';
@import 'src/scss/main/_useful-links.scss';
@import 'src/scss/components/_footer.scss';
@import 'src/scss/components/_path.scss';
@import 'src/scss/components/_building.scss';
@import 'src/scss/components/_sms-auth-popup.scss';
@import 'src/scss/account.scss';
@import 'src/scss/components/_pagination.scss';


.header {
    margin: 0;
    font-family: 'BebasNeue-Bold';
    font-size: 44px;
    color: $active-button-color;
    text-transform: uppercase;
    margin-bottom: 30px;

    @media screen and (max-width: 39.9375em) {
        font-size: 24px;
        letter-spacing: .05em;
        margin-bottom: 15px;
        text-align: center;
    }
}

.cart {
    background : {
        color: #f8f8f8;
        image: url('/images/cart.svg');
        size: 24px 29px;
        position: center;
        repeat: no-repeat;
    }
    width: 55px;
    height: 55px;
    position: fixed;
    right: 0;
    top: 50%;
    z-index: 99;
    transition: .3s;

    @media screen and (max-width: 39.9375em) {
        display: none;
    }

    &:hover {
        background : {
            color: $background-color;
            image: url('/images/cart-hover.svg');
        }

        .cart__count {
            background-color: $active-button-color;
            color: $main-background-color;
        }
    }
}

.cart__wrapper {
    width: 100%;
    height: 100%;
    position: relative;
}

.cart__count {
    border-radius: 50%;
    width: 16px;
    height: 16px;
    background-color: $main-background-color;
    font-family: 'SourceSansPro-Regular';
    font-size: 10px;
    color: $text-color;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 10px;
    bottom: 10px;
}

.return {
    display: block;
    font-family: 'SourceSansPro-Regular';
    font-size: 12px;
    color: $logo-text-color;
    text-decoration: none;
    margin: 30px 0;
    padding-left: 20px;
    background : {
        image: url('/images/return.svg');
        size: 10px;
        position: left center;
        repeat: no-repeat;
    }

    @media screen and (max-width: 1024px) {
        margin: 30px 10px;
    }
}

table {
    overflow-x: auto !important;
}

