.useful-links {
    max-width: 1170px;
    margin: 0 auto 70px;
    overflow: hidden;
    position: relative;

    @media screen and (max-width: 39.9375em) {
        margin-bottom: 30px;
        padding: 0 10px;
    }

    @media screen and (min-width: 40em) and (max-width: 1024px) {
        padding: 0 10px;
    }
}

.useful-links__top {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 39.9375em) {
        justify-content: center;
    }
}

.useful-links__control {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.useful-links__prev, .useful-links__next {
    border: none;
    background : {
        color: transparent;
        size: contain;
        position: center;
        repeat: no-repeat;
    }
    width: 10px;
    height: 10px;
    cursor: pointer;

    @media screen and (max-width: 39.9375em) {
        position: absolute;
        z-index: 2;
        top: 50%;
    }
}

.useful-links__prev {
    background-image: url('/images/useful-links-prev.svg');
    margin-right: 10px;

    @media screen and (max-width: 39.9375em) {
        left: 10px;
    }

    &.swiper-button-disabled {
        background-image: url('/images/useful-links-prev-disabled.svg');
    }
}

.useful-links__separator {
    width: 1px;
    height: 20px;
    background-color: $background-color;

    @media screen and (max-width: 39.9375em) {
        display: none;
    }
}

.useful-links__next {
    background-image: url('/images/useful-links-next.svg');
    margin-left: 10px;

    @media screen and (max-width: 39.9375em) {
        right: 10px;
    }

    &.swiper-button-disabled {
        background-image: url('/images/useful-links-next-disabled.svg');
    }
}

.useful-links__wrapper {
    display: flex;
    flex-wrap: wrap;

    @media screen and (max-width: 39.9375em) {
        flex-wrap: nowrap;
    }
}

.useful-link {
    border: 1px solid $background-color;
    box-sizing: border-box;
    height: 170px;
    font-family: 'SourceSansPro-Regular';
    font-size: 22px;
    color: $active-button-color;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .3s;
    text-decoration: none;
    padding: 40px 35px;
    position: relative;
    background-position: center;

    @media screen and (max-width: 39.9375em) {
        flex-shrink: 0;
    }

    &:nth-child(2n-1):hover {
        background : {
            image: url('/images/useful-link-background.jpg');
            size: cover;
            position: center;
            repeat: no-repeat;
        }
        color: $main-background-color;
        box-shadow: 0 0 10px rgba(62, 181, 241, 0.49);

        .useful-link__h {
            color: $main-background-color;
        }

        .useful-link__separator {
            background-color: $main-background-color;
        }

        .useful-link__url {
            color: $main-background-color;
        }
    }
}

.useful-link__h {
    position: absolute;
    left: 35px;
    top: 40px;
    font-size: 16px;
    color: $text-color;
    line-height: 21px;
}

.useful-link__separator {
    position: absolute;
    left: 35px;
    top: 92px;
    width: 30px;
    height: 1px;
    background-color: $background-color;
}

.useful-link__url {
    position: absolute;
    left: 35px;
    top: 115px;
    font-size: 14px;
    color: $active-button-color;
}
