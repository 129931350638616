.timetables {
    background-color: $logo-h-color;
    margin-bottom: 70px;

    @media screen and (max-width: 39.9375em) {
        margin-bottom: 30px;
    }
}

.timetables__wrapper {
    max-width: 1170px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    @media screen and (max-width: 39.9375em) {
        min-height: 170px;
        box-sizing: border-box;
        padding: 16px;
    }

    @media screen and (min-width: 40em) and (max-width: 1024px) {
        padding: 30px;
        justify-content: center;
    }
}

.timetables__image {
    background : {
        image: url('/images/timetables.jpg');
        size: cover;
        position: center;
        repeat: no-repeat;
    }
    max-width: 469px;
    width: 100%;
    height: 216px;
    flex-shrink: 0;

    @media screen and (max-width: 1024px) {
        display: none;
    }
}

.timetables__shadow {
    background-color: $active-button-color;
    width: 60px;
    height: 216px;
    transform: skewX(350deg);
    position: relative;
    right: 20px;

    @media screen and (max-width: 1024px) {
        display: none;
    }
}

.timetables__shadow_step_two {
    opacity: 0.5;
}

.timetables__shadow_step_three {
    opacity: 0.3;
    margin-right: 10px;
}

.timetables__h {
    max-width: 230px;
    font-family: 'BebasNeue-Bold';
    font-size: 36px;
    line-height: 43px;
    color: $main-background-color;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    flex-shrink: 0;

    @media screen and (max-width: 39.9375em) {
        max-width: 131px;
        font-size: 22px;
        line-height: 26px;
    }
}

.timetables__links {
    max-width: 246px;
    flex-shrink: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media screen and (max-width: 39.9375em) {
        max-width: 152px;
    }
}

.timetables__link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 72px;
    height: 62px;
    box-sizing: border-box;
    border: 2px solid $main-background-color;
    background-color: transparent;
    transform: skewX(350deg);
    margin-bottom: 13px;
    transition: .3s;

    @media screen and (max-width: 39.9375em) {
        width: 47px;
        height: 40px;
    }

    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6) {
        position: relative;
        right: 10px;
    }

    &:hover {
        background-color: $active-button-color;
    }
}

.timetables__link-span {
    background : {
        size: contain;
        position: center;
        repeat: no-repeat;
    }
    transform: skewX(-350deg);
}

.timetables__link-span_type_chess {
    background-image: url('/images/chess-link.svg');
    width: 22px;
    height: 30px;
}

.timetables__link-span_type_skates {
    background-image: url('/images/skates-link.svg');
    width: 32px;
    height: 30px;
}

.timetables__link-span_type_pool {
    background-image: url('/images/pool-link.svg');
    width: 33px;
    height: 30px;
}

.timetables__link-span_type_ping-pong {
    background-image: url('/images/ping-pong-link.svg');
    width: 29px;
    height: 30px;
}

.timetables__link-span_type_gym {
    background-image: url('/images/gym-link.svg');
    width: 30px;
    height: 30px;
}

.timetables__link-span_type_hockey {
    background-image: url('/images/hockey-link.svg');
    width: 24px;
    height: 30px;
}
