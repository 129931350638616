.subdivisions {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    max-width: 1440px;
    margin: 0 auto;
}

.subdivision {
    max-width: 570px;
    width: 100%;
    flex-shrink: 0;
    height: 290px;
    position: relative;
    margin: 0 15px 70px;
    background : {
        size: cover;
        position: center;
        repeat: no-repeat;
    }
    display: block;
    text-decoration: none;
    overflow: hidden;
    transition: .3s;

    &:hover {
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);

        .subdivision__highlighter {
            opacity: 1;
        }
    }
}

.subdivision_type_ice-palace {
    background-image: url('/images/ice-palace-background.jpg');
}

.subdivision_type_waterpool {
    background-image: url('/images/waterpool-background.jpg');
}

.subdivision__highlighter {
    position: absolute;
    bottom: 36px;
    height: 63px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $main-background-color;
    opacity: 0.9;
    transform: skewX(330deg);
}

.subdivision__highlighter_type_ice-palace {
    right: -20px;
    width: 298px;
}

.subdivision__highlighter_type_waterpool {
    left: -20px;
    width: 358px;
}

.subdivision__h {
    font-family: 'BebasNeue-Bold';
    font-size: 24px;
    color: $active-button-color;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    transform: skewX(-330deg);
}
