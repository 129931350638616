@import 'src/scss/mixins/_global.scss';
@import 'src/scss/_variables.scss';

.sms-auth {

    max-width: 328px;
    @include main-font;

    &__image {

        @include bg-img;
        margin: 69px auto 10px;
        background-image: url(/images/account-prompt.svg);
        width: 36px;
        height: 36px;
    }

    &__title {

        font-size: 18px;
        color: #2a2a2a;
        text-align: center;
        margin-bottom: 13px;
    }

    &__text {

        font-size: 12px;
        color: #787878;
        text-align: center;
        margin: 0 auto 12px;
    }

    &__form {
        display: flex;
        flex-direction: column;
    }

    &__tel {

        margin: 0 auto 12px;
        border: 1px solid #e5e5e5;
        color: #2a2a2a;
        text-align: center;
        height: 40px;
        width: 100%;
        max-width: 268px;
    }

    &__label {

        color: #828282;
        margin: 0 auto 12px;
    }

    &__agreement {

        color: #828282;
        text-decoration: none;
        font-size: 12px;
    }

    &__button {

        display: flex;
        margin-top: 12px;
        font-size: 16px;
        color: #fff;
        justify-content: center;
        width: 268px;
        height: 50px;
        margin: 0 auto 30px;
        box-sizing: border-box;
        border: 1px solid $background-color;
        background-color: $background-color;
        transition: .3s;
        cursor: pointer;
        align-items: center;

        &:hover {
            background-color: $main-background-color;
            color: $background-color;
        }
    }

    &__recaptcha-wrapper {
        margin: 5px 10px;
    }
}
