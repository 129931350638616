.foot {
    background-color: $active-button-color;
    padding: 85px 0 32px;

    @media screen and (max-width: 1024px) {
        padding: 30px;
        box-sizing: border-box;
    }
}

.foot__wrapper {
    max-width: 1170px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;

    > * {
        flex-shrink: 0;
    }
}

.foot__logo {
    display: block;
    background : {
        image: url('/images/footer_logo.png');
        size: contain;
        position: center;
        repeat: no-repeat;
    }
    width: 100px;
    height: 133px;

    @media screen and (max-width: 39.9375em) {
        width: 41px;
        height: 54px;
        margin: 0 auto 20px;
    }

    @media screen and (min-width: 40em) and (max-width: 1024px) {
        margin-bottom: 20px;
    }
}

.foot__container {
    max-width: 1040px;
    width: 100%;
}

.foot__top {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 11px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    margin-bottom: 36px;

    @media screen and (max-width: 39.9375em) {
        border: none;
        justify-content: center;
        text-align: center;
    }

    > * {
        flex-shrink: 0;
    }
}

.foot__nameplate {
    max-width: 280px;
    width: 100%;

    @media screen and (min-width: 40em) and (max-width: 1024px) {
        margin-bottom: 30px;
    }
}

.foot__header {
    font-family: 'BebasNeue-Bold';
    font-size: 18px;
    color: $main-background-color;
    letter-spacing: .05em;
    margin-bottom: 12px;

    @media screen and (max-width: 39.9375em) {
        font-size: 14px;
        margin-bottom: 7px;
    }
}

.foot__refinement {
    font-family: 'SourceSansPro-Regular';
    font-size: 14px;
    color: $main-background-color;
    line-height: 16px;
    margin-bottom: 37px;

    @media screen and (max-width: 39.9375em) {
        font-size: 12px;
    }
}

.foot__social-networks {
    display: flex;
    align-items: center;
    font-family: 'SourceSansPro-Bold';
    font-size: 12px;
    color: $main-background-color;

    > * {
        margin-left: 13px;

        @media screen and (max-width: 39.9375em) {
            margin-left: 0;
        }
    }
}

@media screen and (max-width: 39.9375em) {

    .foot__social-networks_for_desktop {
        display: none;
    }
}

.foot__social-networks_for_mobile {
    display: none;

    @media screen and (max-width: 39.9375em) {
        display: block;
    }
}

.foot__icons {
    margin-top: 10px;
}

.foot__facebook {
    background : {
        image: url('/images/facebook-footer.svg');
        size: contain;
        position: center;
        repeat: no-repeat;
    }
    width: 20px;
    height: 20px;
    display: block;

    @media screen and (max-width: 39.9375em) {
        display: inline-block;
        margin: 0 5px;
    }

    &:hover {
        background-image: url('/images/facebook-footer-hover.svg');
    }
}

.foot__vk {
    background : {
        image: url('/images/vk-footer.svg');
        size: contain;
        position: center;
        repeat: no-repeat;
    }
    width: 20px;
    height: 20px;
    display: block;

    @media screen and (max-width: 39.9375em) {
        display: inline-block;
        margin: 0 5px;
    }

    &:hover {
        background-image: url('/images/vk-footer-hover.svg');
    }
}

.foot__nav {
    max-width: 500px;
    width: 100%;
}

.foot__nav-ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;

    @media screen and (max-width: 39.9375em) {
        display: block;
    }
}

.foot__nav-li {
    flex-shrink: 0;
    flex-grow: 1;
    flex-basis: 50%;
    margin-bottom: 25px;
}

.foot__nav-link {
    font-family: 'BebasNeue-Bold';
    font-size: 15px;
    color: $main-background-color;
    letter-spacing: .05em;
    text-transform: uppercase;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

@media screen and (max-width: 39.9375em) {
    
    .foot__toolbar {
        display: none;
    }
}

.foot__buy-tickets {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $logo-h-color;
    width: 170px;
    height: 50px;
    margin-bottom: 10px;
    box-sizing: border-box;
    font-family: 'SourceSansPro-Regular';
    font-size: 14px;
    color: $main-background-color;
    text-decoration: none;
    padding-left: 21px;
    background : {
        image: url('/images/tickets.svg');
        size: 15px 16px;
        position: 30px center;
        repeat: no-repeat;
    }
    transition: .5s;

    &:hover {
        box-shadow: 0 4px 7px rgba(62, 181, 241, 0.7);
    }
}

.foot__account-wrapper {
    width: 170px;
    height: 50px;
    box-sizing: border-box;
    border: 1px solid transparent;
    background-color: transparent;
    transition: .5s;
    padding: 0;
    cursor: pointer;

    &:hover {
        box-shadow: 0 4px 7px rgba(255, 255, 255, 0.7);
        border-color: $main-background-color;
    }
}

.foot__account {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    font-family: 'SourceSansPro-Regular';
    font-size: 14px;
    color: $main-background-color;
    border: 1px solid $main-background-color;
    padding-left: 21px;
    background : {
        image: url('/images/account-footer.svg');
        size: 12px 16px;
        position: 20px center;
        repeat: no-repeat;
    }
}

.foot__contacts {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    box-sizing: border-box;
    padding-right: 8%;

    @media screen and (max-width: 39.9375em) {
        display: none;
    }

    > * {
        flex-shrink: 0;
    }
}

.foot__contacts-h {
    font-family: 'BebasNeue-Bold';
    font-size: 14px;
    color: $main-background-color;
    letter-spacing: .05em;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.foot__contacts-block_type_two-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 210px;
    width: 100%;
}

.foot__contact {
    margin-bottom: 20px;
}

.foot__contact-h {
    font-family: 'SourceSansPro-Bold';
    font-size: 12px;
    color: $main-background-color;
    margin-bottom: 10px;
}

.foot__contact-value {
    font-family: 'SourceSansPro-Regular';
    font-size: 12px;
    color: $main-background-color;
    display: inline-block;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.foot__contacts-block_type_flex {
    display: flex;

    > * {
        margin-right: 35px;
    }
}
