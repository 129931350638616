.main {
    display: block;
    margin: 0 auto;
}

.main__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 1160px;
    margin: 0 auto 100px;

    @media screen and (max-width: 39.9375em) {
        margin-bottom: 30px;
    }

    @media screen and (min-width: 40em) and (max-width: 1024px) {
        flex-wrap: nowrap;
        padding: 0 10px;
        justify-content: initial;
        box-sizing: border-box;
    }
}
