@import 'src/scss/_variables.scss';

.main {
    max-width: 1160px;
    margin: 0 auto 80px;
}

@media screen and (min-width: 40em) and (max-width: 1024px) {

    .header {
        padding: 0 10px;
    }
}

.account__wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-top: 114px;

    @media screen and (max-width: 1024px) {
        margin-top: 50px;
    }
}

.account__control-panel {
    flex-shrink: 0;
    max-width: 295px;
    width: 100%;

    @media screen and (max-width: 1024px) {
        padding: 0 10px;
    }
}

.account__header {
    font-family: 'BebasNeue-Bold';
    font-size: 28px;
    color: $active-button-color;
    letter-spacing: .05em;
    text-transform: uppercase;
    margin-bottom: 58px;
}

.account__link {
    display: block;
    font-family: 'SourceSansPro-Regular';
    font-size: 18px;
    color: $logo-text-color;
    text-decoration: none;
    margin-bottom: 27px;
    padding-left: 24px;
    background : {
        size: 14px;
        position: left center;
        repeat: no-repeat;
    }
}

.account__link_type_info {
    background-image: url('/images/account-info.svg');
}

.account__link_type_orders {
    background-image: url('/images/account-orders.svg');
}

.account__link_type_exit {
    background-image: url('/images/account-exit.svg');
}

.account__info {
    flex-shrink: 0;
    flex-grow: 1;

    @media screen and (max-width: 1024px) {
        padding: 0 10px;
    }
}

.account__info-h {
    font-family: 'SourceSansPro-Regular';
    font-size: 18px;
    color: $background-color;
    margin-bottom: 25px;
}

.account__inputs {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;

    @media screen and (max-width: 1024px) {
        flex-direction: column;
    }
}

.account__input {
    font-family: 'SourceSansPro-Regular';
    font-size: 16px;
    color: $text-color;
    box-sizing: border-box;
    padding: 0 20px;
    height: 60px;
    border: 1px solid #eee;
    transition: .3s;
    margin-bottom: 40px;
    display: block;

    &:focus {
        border-color: $background-color;
    }
}

.account__input_type_surname, .account__input_type_e-mail {
    width: 270px;
}

.account__input_type_name {
    width: 170px;
}

.account__input_type_patronym {
    width: 370px;
}

.account__input_type_phonenumber {
    width: 200px;
    margin-left: 30px;
    margin-right: auto;

    @media screen and (max-width: 1024px) {
        margin: 0 0 40px;
    }
}

.account__info-separator {
    height: 1px;
    background-color: #f2f2f2;
    margin-bottom: 30px;
}

.account__info-update {
    font-family: 'SourceSansPro-Bold';
    font-size: 18px;
    color: $main-background-color;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 270px;
    height: 50px;
    box-sizing: border-box;
    border: 1px solid $background-color;
    background-color: $background-color;
    transition: .3s;
    cursor: pointer;

    &:hover {
        background-color: $main-background-color;
        color: $background-color;
    }
}

.account__orders {
    flex-shrink: 0;
    flex-grow: 1;

    @media screen and (max-width: 39.9375em) {
        flex-shrink: 1;
    }

    @media screen and (min-width: 40em) and (max-width: 1024px) {
        width: 100%;
        padding: 0 10px;
        box-sizing: border-box;
    }
}

.account__orders-h {
    font-family: 'SourceSansPro-Regular';
    font-size: 20px;
    color: $text-color;
    margin-bottom: 40px;

    @media screen and (max-width: 39.9375em) {
        padding: 0 10px;
    }
}

.account__table {
    font-family: 'SourceSansPro-Regular';
    font-size: 16px;
    color: $text-color;
}

.account__row {
    display: flex;
    align-items: center;
    min-height: 47px;
    box-sizing: border-box;

    @media screen and (max-width: 39.9375em) {
        flex-wrap: wrap;
    }

    > * {
        flex-grow: 1;
        flex-basis: 0;
        padding: 10px;
        box-sizing: border-box;

        @media screen and (max-width: 39.9375em) {
            flex-grow: 0;
            flex-basis: 50%;
        }
    }

    &:nth-child(even) {
        background-color: #fafafa;
    }
}

.account__row_head {
    font-size: 14px;
    color: $logo-text-color;

    @media screen and (max-width: 39.9375em) {
        display: none;
    }
}
