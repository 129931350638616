@import 'src/scss/_variables.scss';

.building {
    flex-shrink: 0;
    max-width: 870px;
    width: 100%;

    @media screen and (min-width: 40em) and (max-width: 1024px) {
        flex-shrink: 1;
    }
}

.building__picture {
    background : {
        size: contain;
        position: top center;
        repeat: no-repeat;
    }
    height: 375px;
    margin-bottom: 30px;

    @media screen and (max-width: 39.9375em) {
        height: 170px;
    }
}

.building__header {
    margin: 0;
    padding: 0;
    font-family: 'SourceSansPro-Bold';
    font-size: 30px;
    color: $active-button-color;
    margin-bottom: 25px;

    @media screen and (max-width: 39.9375em) {
        font-size: 20px;
        padding: 0 10px;
    }
}

.building__text {
    margin: 0;
    padding: 0;
    font-family: 'SourceSansPro-Regular';
    font-size: 16px;
    color: $text-color;
    line-height: 29px;
    margin-bottom: 25px;

    @media screen and (max-width: 39.9375em) {
        padding: 0 10px;
    }
}

.building__banner {
    background : {
        image: url('/images/building-banner.jpg');
        size: contain;
        position: center;
        repeat: no-repeat;
    }
    display: block;
    height: 217px;

    @media screen and (max-width: 39.9375em) {
        height: 170px;
    }
}
