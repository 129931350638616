@mixin bg-img {

    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

@mixin main-font {
    font-family: SourceSansPro-Regular;
}
