.billboard {
    position: relative;
    overflow: hidden;
    max-width: 1440px;
    width: 100%;
    margin: 0 auto 70px;

    @media screen and (max-width: 39.9375em) {
        margin-bottom: 30px;
    }
}

.billboard__slides {
    display: flex;
}

.billboard__slide {
    width: 100%;
    height: 520px;
    flex-shrink: 0;
    background : {
        image: url('/images/billboard.jpg');
        size: cover;
        position: center;
        repeat: no-repeat;
    }

    @media screen and (max-width: 39.9375em) {
        height: 170px;
    }
}

.billboard__prev {
    background-color: $background-color;
    width: 50px;
    height: 50px;
    position: absolute;
    top: calc(50% - 25px);
    left: 10%;
    cursor: pointer;
    transform: rotate(45deg);
    opacity: 0.8;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .3s;
    box-sizing: border-box;

    @media screen and (max-width: 39.9375em) {
        display: none;
    }

    &:hover {
        opacity: 1;
        box-shadow: 0 0 15px rgba(62, 181, 241, 0.7);
    }
}

.billboard__prev-span {
    display: block;
    transform: rotate(-45deg);
    flex-shrink: 0;
    background : {
        image: url('/images/billboard-prev-span.svg');
        size: contain;
        position: center;
        repeat: no-repeat;
    }
    width: 16px;
    height: 16px;
}

.billboard__next {
    background-color: $background-color;
    width: 50px;
    height: 50px;
    position: absolute;
    top: calc(50% - 25px);
    right: 10%;
    cursor: pointer;
    transform: rotate(45deg);
    opacity: 0.8;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .3s;
    box-sizing: border-box;

    @media screen and (max-width: 39.9375em) {
        display: none;
    }

    &:hover {
        opacity: 1;
        box-shadow: 0 0 15px rgba(62, 181, 241, 0.7);
    }
}

.billboard__next-span {
    display: block;
    transform: rotate(-45deg);
    flex-shrink: 0;
    background : {
        image: url('/images/billboard-next-span.svg');
        size: contain;
        position: center;
        repeat: no-repeat;
    }
    width: 16px;
    height: 16px;
}

.billboard__pagination {
    display: none;

    @media screen and (max-width: 39.9375em) {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 14px;
        width: 100%;
    }
}

.billboard__bullet {
    display: inline-block;
    margin: 0 5px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    box-sizing: border-box;
    border: 1px solid $main-background-color;

    &.swiper-pagination-bullet-active {
        background-color: $main-background-color;
    }
}
