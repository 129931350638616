.pagination {
    display: flex;
    flex-direction: row;

    &__item {
        width: 1.5em;
        height: 1.5em;
        display: flex;
        justify-content: center;
        align-items: center;

        &_active {
            font-weight: bold;
        }
    }

    &__link {


        &_prev {
            &::before {
                content: '<';
            }
        }

        &_next {
            &::before {
                content: '>';
            }
        }
    }

}
